import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Arora House, Bikaner",
  address: "Bikaner",
  client: "Tej Arora",
  area: "4,800 Sq. Ft.",
  project: "Arora House, Bikaner",
  status: "Completed",
  category: "Residential | Architecture | House",
  backlink: "/projects/residential/",
};

export default class Projectarorahousebikaner extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Residential Projects | Arora House, Bikaner"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View details of the magnificent Arora House located in Bikaner and spanning 4,800 sq. ft. designed and developed for Tej Arora."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
