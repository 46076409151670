const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/arorahouse/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/arorahouse/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/arorahouse/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/arorahouse/4.png",
    text: "4",
  },
];

export default data;
